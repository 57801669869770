@keyframes shadow-drop-bottom {
    0% {
        box-shadow: 0 0 0 0 transparent;
    }
    to {
        box-shadow: 0 12px 20px -12px rgb(0 0 0/35%);
    }
}

.rectangleDiv,
.rectangleDiv1 {
    position: absolute;
    top: 12px;
    left: 0;
    border-radius: 28px;
    background-color: var(--color-red);
    width: 295.93px;
    height: 169px;
    opacity: 0.5;
}
.rectangleDiv1 {
    top: 0;
    left: 18.55px;
    border-radius: 23px;
    background-color: var(--color-pink-100);
    width: 284.45px;
    height: 166px;
    opacity: 0.7;
}
.skyBetsCelebtratingLoveFor {
    position: absolute;
    top: 28px;
    left: 39.75px;
    display: inline-block;
    width: 241.16px;
    height: 98px;
}
.groupDiv {
    position: relative;
    width: 303px;
    height: 181px;
    flex-shrink: 0;
}
.loginYourAccount,
.mobileNumberDiv {
    position: relative;
    font-size: var(--font-size-2xl);
    font-family: var(--font-inika);
    color: var(--color-font);
    text-align: center;
    display: inline-block;
    width: 100%;
    height: 27px;
    flex-shrink: 0;
}
.mobileNumberDiv {
    font-size: var(--font-size-base);
    width: 152.87px;
    height: 13px;
}

.forgotDiv {
    position: relative;
    font-size: var(--font-size-base);
    font-family: var(--font-inika);
    color: var(--color-font);
    text-align: center;
    display: inline-block;
}
.frameDiv {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
}


.signupDiv {
    position: relative;
    font-size: var(--font-size-base);
    font-family: var(--font-inika);
    color: var(--color-white);
    text-align: center;
    display: inline-block;
}
.frameDiv1,
.frameForm {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}
.frameForm {
    border-radius: var(--br-xl);
    flex-direction: column;
    padding: var(--padding-3xl) var(--padding-xl);
    box-sizing: border-box;
    justify-content: flex-start;
    gap: var(--gap-md);
    cursor: pointer;
}
.signupDiv1 {
    position: relative;
    display: inline-block;
}
.frameDiv2 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    font-size: var(--font-size-xs);
    color: var(--color-black);
}
.loginDiv {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: var(--padding-lg) var(--padding-2xs);
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-xl);
    text-align: left;
    font-size: var(--font-size-lg);
    color: var(--color-gray-500);
    font-family: var(--font-inika);
}
