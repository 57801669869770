.sidenav {
    width: 100%;
    background: rgba(0, 0, 0, 0.49);
    height: 100vh;
    position: absolute;
    z-index: 50;
}
.main-content {
    width: 80%;
    max-width: 350px;
    background: white;
    height: 100vh;
    transition: 0.4s;
 }

.actions {
    margin: 5px;
    border-radius: 8px;
    padding: 5px 6px;
    flex-direction: column;
    display: flex;
}

.actions span {
    font-weight: 800;
    text-align: center;
    margin-bottom: 5px;
}

.actions .colored {
    padding: 4px;
    border-radius: 8px;
}

.actions img {
    width: 100%;
}

/*@-webkit-keyframes animateThis {*/
/*    0% {*/
/*        width: 0;*/
/*        height: 0;*/
/*    }*/
/*    100% {*/
/*        width: 100%;*/
/*        height: 100%;*/
/*    }*/
/*}*/

.market_box {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 8px;
    border-radius: 5px;
    border: solid 2px #ffffff;
    flex-direction: column;
    color: white;
    padding: 7px 1px;
    animation: shine 2s infinite alternate;
    animation-fill-mode: forwards;
    box-shadow: -1px 1px 16px -7px rgba(0,0,0,0.75);
    -webkit-box-shadow: -1px 1px 16px -7px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 1px 16px -7px rgba(0,0,0,0.75);
}
@keyframes shine{
    10% {
      opacity: 1;
      top: -30%;
      left: -30%;
      transition-property: left, top, opacity;
      transition-duration: 0.7s, 0.7s, 0.15s;
      transition-timing-function: ease;
    }
    100% {
      opacity: 0.9;
      top: -30%;
      left: -30%;
      transition-property: left, top, opacity;
      transition-timing-function: ease;
    }
    
  }
.market_box .times {
    border-radius: 10px 10px 0px 0px;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
}

.market_box .main {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    
}

.border_box {
    display: flex;
    width: 100%;
    padding: 5px;
    border: solid 2px #e06407;
    border-radius: 4px;
    flex-direction: column;
    text-align: center;
    margin: 10px 5px 8px;
}

.market_play_button_box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.market_play_button_box img {
    width: 40px;
    margin-bottom: 4px;
}

.market_play_button_box span {
    color: black;
    font-weight: 600;
    font-size: 11px;
}

.inner_market_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.inner_market_box img {
    width: 85px;
}

.market_time {
    font-size: 12px;
    font-weight: 600;
}

.market_name {
    font-size: 18px;
    font-weight: 800;
}

.market_result {
    font-weight: 600;
    font-size: 22px;
}

.market_status {
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 5px;
}
.market_status {
    font-weight: 600;
}

.button_title {
    color: #097d0c;
    font-weight: 600;
}

.date-block {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}

.date-block span {
    font-weight: 600;
}

.activeButton {
    background: #097d0c;
    color: white;
    border-radius: 23px;
    padding: 8px 5px;
    width: 124px;
    text-align: center;
}



.inactiveButton {
    background: var(--button-background);
    color: white;
    border-radius: 23px;
    padding: 8px 5px;
    width: 124px;
    text-align: center;
}

.main-game-screen {
    padding: 15px;
}

.main-game-screen input {
    width: 100%;
    margin-bottom: 10px;
}
.main-game-screen .edittext {
    width: 100%;
    margin-bottom: 10px;
}
.main-game-screen button {
     width: 100%;
     margin-bottom: 10px;
 }

.wallet-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 22px 85px;
    padding: 27px 0;
    background: #000428;
    border-radius: 26px;
}

.wallet-box .title {
    color: white;
    font-size: 35px;
    text-decoration: underline;
    text-underline-offset: 7px;
}

.wallet-box .subtitle {
    color: white;
    font-size: 27px;
}

.bet-list {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10px;
    padding: 5px;
    font-size: 14px;
}

.bottom-border {
    border-bottom: solid 1px #000;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
}

.bet-shadow {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.bet-list span {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: 500;
}

.bet-list img {
    height: 24px;
    margin-left: 7px;
}

.nav-item {
    display: flex;
    padding: 14px;
}

.nav-item img {
    height: 21px;
    margin-right: 15px;
}

.upi_apps {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
}
.upi_apps div {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.upi_apps img {
    height: 55px;
}

.upi_apps .app-name {
    color: black;
    margin-top: 4px;
    font-size: 16px;
    font-weight: 600;
}

.games {
    margin-top: 10px;
    display: flex;
    margin-bottom: 10px;
    justify-content: space-around;
}

.games img {
    height: 57px;
    width: 57px;
}


.upi_apps .title {
    color: black;
    font-size: 35px;
    text-decoration: underline;
    text-underline-offset: 7px;
    font-weight: 500;
}


.upi_apps .heading {
    color: black;
    width: 80%;
    text-align: center;
    padding-bottom: 10px;
    font-size: 25px;
    border-bottom: solid 1px #000;
    font-weight: 500;
}

.upi_apps .subheading {
    color: black;
    margin-top: 10px;
    font-size: 22px;
    font-weight: 500;
}

.upi_apps .subtitle {
    color: black;
    font-size: 27px;
    font-weight: 500;
}

.pageTitle {
    font-weight: 600;
    font-size: 18px;
}
.transaction_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.transaction_block div {
    display: flex;
    flex-direction: column;
}

.transaction_block span {
    font-weight: 600;
}

.spdptp {
    display: flex;
}

.spdptp button {
    margin-left: 5px;
    margin-right: 5px;
}

.withdraw-form button {
    width: 100%;
    margin-top: 15px;
}

.confirm-box {
    margin-top: 20px;
    text-align: center;
    background: #000428;
    border-radius: 11px;
    padding: 10px;
    -webkit-box-shadow: 2px 4px 22px 0 rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 4px 22px 0 rgba(0,0,0,0.75);
    box-shadow: 2px 4px 22px 0 rgba(0,0,0,0.75);
}

.confirm-box span {
    color: white;
}

.confirm-box div {
    display: flex;
    justify-content: space-around;
    margin-top: 13px;
}

.confirm-box button {
    width: 100%;
    margin-right: 10px;
    margin-left: 10px;
}

.homemenu .homediv {
    border-radius: 10px;
    border: solid 2px #76551e;
    background: #5663a0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
}

.homemenu .homediv img {
    height: 39px;
    margin-top: 3px;
    width: 39px;
}

.homemenu .homediv span {
    font-size: 12px;
    margin-bottom: 3px;
    margin-top: 3px;
    color: white;
}



.homemenu .homebigdiv {
    display: flex;
    background: #fff;
    border-radius: 25px;
    padding: 4px;
    background: #F99C1A;
}

.homemenu .homebigdiv img {
    height: 35px;
    width: 35px;
    margin-right: 8px;
}

.homemenu .homebigdiv span {
    font-weight: 700;
    text-align: center;
    margin-bottom: 5px;
    color: black;
    font-size: 12px;
}

.homebigdiv a {
    width: 100%;
    display: flex;
    align-items: center;
}


.homemenu .homebigdiv p {
    font-weight: 700;
    text-align: center;
    margin-bottom: 0px;
    color: black;
    font-size: 16px;
    width: 100%;
}

.play_result {
    background: #000429;
    color: white;
    border-radius: 10px;
    padding: 5px;
    display: flex;
    align-items: center;
}

.play_result img {
    height: 30px;
    width: 30px;
}

.play_result span {
    font-size: 14px;
    margin-right: 5px;
    margin-left: 7px;
    font-weight: 600;
}

.gamediv {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 11px;
    border-radius: 12px;
}

.gamediv div {
    height: 82px;
    width: 81px;
    background: #000428;
    background: -webkit-linear-gradient(to bottom, #5663a0, #000428);
    background: linear-gradient(to bottom, #000428, #000000);
    border-radius: 45px;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
}


.gamediv div img {
    height: 50px;
    width: 50px;
}

.gamediv span {
    font-size: 14px;
    font-weight: 800;
    margin-top: 7px;
    background: #a8b1ffeb;
    position: absolute;
    margin-top: 40px;
    padding: 50px 20px 20px 20px;
    border-radius: 10px;
    width: 154px;
    text-align: center;
}

.border_boxer {
    display: flex;
    width: 100%;
    padding: 5px;
    border-radius: 4px;
    flex-direction: column;
    text-align: center;
    background: var(--color-accent);
    border-radius: 10px;
    margin-bottom: 11px;
    color: white;
}

.block_button {
    background: #fff;
    border-radius: 10px;
    width: 100%;
    margin: 10px 5px 8px;
    padding: 6px;
    line-height: 19px;
}

.block_button .button_title {
    color: var(--color-accent) !important;
}