/*noinspection CssUnknownTarget*/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

body * {
  font-family: 'Open Sans', sans-serif !important;
}

body {
  margin: 0;
  line-height: normal;
}


.loader-block {
  box-shadow: 1px 1px 12px 0px rgba(1,141,150,0.75);
-webkit-box-shadow: 1px 1px 12px 0px rgba(1,141,150,0.75);
-moz-box-shadow: 1px 1px 12px 0px rgba(1,141,150,0.75);
background: white;
padding: 20px;
border-radius: 10px;
      }

      .loader-parent {
        height: 100%;
        width: 100%;
        max-width: 500px;
        position: absolute;
        display: flex;
        flex-direction: column;
        z-index: 9999;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.103);
      }

      .loader {
        width: 50px;
        padding: 8px;
        aspect-ratio: 1;
        border-radius: 50%;
        background: #25b09b;
        --_m: 
          conic-gradient(#0000 10%,#000),
          linear-gradient(#000 0 0) content-box;
        -webkit-mask: var(--_m);
                mask: var(--_m);
        -webkit-mask-composite: source-out;
                mask-composite: subtract;
        animation: l3 1s infinite linear;
      }
      @keyframes l3 {to{transform: rotate(1turn)}}


.logoIcon {
  position: relative;
  width: 225px;
  flex-shrink: 0;
  object-fit: cover;
}

.review-bets-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 0 0 10px 0;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dialog-header {
  background-color: var(--color-accent);
  color: #fff;
  padding: 8px 16px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
}

.dialog-content .bets-table {
  overflow: scroll;
  max-height: 40vh;
}

.dialog-actions button {
  border: none;
  width: 49%;
}

.dialog-actions {
  padding-left: 10px;
  padding-right: 10px;
}

.bet-details {
  font-size: 14px;
}

.bet-details span {
  margin-right: 5px;
}

.balance-details {
  font-size: 14px;
}

.balance-details span {
  margin-right: 5px;
}

.dialog-header h2 {
  font-size: 16px;
  margin-bottom: 0;
}

.bets-table {
  margin-top: 16px;
}

.table-header {
  border-color: #363636;
  border-width: 1px 0;
  border-style: solid;
  font-weight: 800;
  padding: 5px 10px 5px 10px;
  display: flex;
  font-size: 14px;
}

.table-header div {
  flex: 1;
  text-align: center;
  font-weight: bold;
}

.bet-details,
.balance-details {
  display: flex;
  margin-top: 16px;
}

.bet-details div {
  width: 49%;
}

.depositCash {
  margin-top: 15px;
  display: flex;
  justify-content: space-around;
}

.depositCash span {
  width: 100%;
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
  border: solid 1px #000000a1;
  border-radius: 6px;
  padding: 6px;
  cursor: pointer;
  font-size: 14px;
}


.minimumText {
  text-align: center;
  font-size: 13px;
  margin: 20px 0px 0px 0;
  font-weight: 500;
}


.popUpView {
  position: fixed;
  width: 100%;
  max-width: 500px;
  bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #0009;
}

.popUpView .bottomBar {
  background: white;
  border-radius: 35px 35px 0px 0px;
  padding: 11px 0px;
}

.popUpView h5 {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px #0003;
  padding-bottom: 10px;
}

.popUpView h5 img {
  height: 30px;
  margin-right: 5px;
}

.barMainView {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.openAppButton {
  border: solid 2px #0000008c;
  border-radius: 10px;
  padding: 7px 14px;
  font-weight: 500;
  margin-top: 13px;
  margin-bottom: 13px;
}

.openAppButton img {
  height: 20px;
  margin-right: 7px;
}

.popUpView .buttonSection {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
}

.popUpView .buttonSection .button {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  border: 0;
  color: white;
  padding: 10px;
  background-color: var(--button-background);
  align-self: stretch;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
}



.upi_apps-d {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}
.upi_apps-d div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-right: 10px;
  margin-left: 10px;
  padding: 10px;
  border: solid 1px #0000006b;
  border-radius: 10px;
}
.upi_apps-d img {
  height: 43px;
}

.upi_apps-d .app-name {
  color: black;
  margin-top: 4px;
  font-size: 13px;
  font-weight: 500;
}



.popUpView .buttonSection .redbutton {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  border: 0;
  color: white;
  padding: 10px;
  background-color: #323232;
  align-self: stretch;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
}

.edittext-d {
  border-radius: 5px;
  border: solid 1px var(--color-accent) !important;
  background-color: var(--color-white);
  align-self: stretch;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding: 11px !important;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}

.edittext-disable-d {
  background: #dddddd;
    border: solid 1px #dddddd !important;
  border-radius: 5px;
  border: solid 1px var(--color-accent) !important;
  background-color: var(--color-white);
  align-self: stretch;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding: 11px !important;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}

.depositBox {
  padding:0px 0px 0px 11px !important
}
.currentBalBlock {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  font-size: 14px;
  border-bottom: solid 1px #0000001a;
}
.starline-result {
  box-shadow: 1px 1px 15px 0px rgb(0 0 0 / 75%);
  -webkit-box-shadow: 1px 1px 15px 0px rgb(0 0 0 / 75%);
  -moz-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.75);
  color: black !important;
  border-radius: 40px;
  font-size: 18px !important;
  padding: 5px 10px;
  margin-right: 10px;
}

.bet-details div,
.balance-details div {
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 8px;
}

.note {
  margin-top: 16px;
  text-align: center;
  font-size: 14px;
  color: #666;
}

.dialog-actions {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.cancel-button,
.submit-button {
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
}

.cancel-button {
  background-color: #f44336;
  color: #fff;
  margin-right: 8px;
}

.submit-button {
  background-color: #4caf50;
  color: #fff;
}

.ratenox {
  background: white;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  padding: 10px;
  color: black;
  border-radius: 40px;
  margin-bottom: 10px;
}

.grid2x2 {
  padding: 10px;
    display: grid;
    gap: 10px;
    grid-template-columns: auto auto
}

.gali_box {
  background: white !important;
  border-radius: 17px !important;
  padding: 0 !important;
  border: none !important;
}

.gali_box .main {
  display: block !important;
  padding: 0 !important;
}

.gali_box .inner_market_box {
  text-align: center !important;
    padding: 5px 5px 5px 10px !important;
}

.gali_box .market_name {
  font-weight: 700;
}

.gali_box .market_result {
  background: black;
    width: 43px;
    height: 43px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

.gali_msg {
  font-size: 13px;
  text-align: left;
  font-weight: 600;
}

.gali_inactive {
  background: #707682;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom-left-radius: 17px;
  border-bottom-right-radius: 17px;
}

.gali_inactive img {
  width: 33px !important;
  margin-right: 10px;
}

.gali_active {
  background: var(--color-accent);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom-left-radius: 17px;
  border-bottom-right-radius: 17px;
}

.gali_active img {
  width: 33px !important;
  margin-right: 10px;
}

.gali_rate {
  font-size: 14px;
  font-weight: 500;
  background: white;
  padding: 7px 63px;
  border-radius: 40px;
  box-shadow: -1px 1px 16px -7px rgba(0, 0, 0, 0.75) !important;
  -webkit-box-shadow: -1px 1px 10px -7px rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: -1px 1px 16px -7px rgba(0, 0, 0, 0.75) !important;
}

.delhi_rate {
  font-size: 14px;
  font-weight: 500;
  background: white;
  padding: 7px;
  border-radius: 40px;
  box-shadow: -1px 1px 16px -7px rgba(0, 0, 0, 0.75) !important;
  -webkit-box-shadow: -1px 1px 10px -7px rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: -1px 1px 16px -7px rgba(0, 0, 0, 0.75) !important;
  margin-bottom: 0 !important;
}

.delhi_rate p {
  text-align: center;
  margin-bottom: 0 !important;
}

.delhi_rate_block {
  text-align: center;
}

.gali_rate_block {
  text-align: center;
  margin-bottom: 0px;
}

.delhi_rate span {
  color: var(--color-accent);
}


.gali_rate span {
  color: var(--color-accent);
}

.grid2x2 .transaction_block {
  padding: 0px;
}

.grid2x2 .chartButton {
  background: var(--color-accent);
  color: white;
  border-radius: 5px;
  padding: 17px 5px;
  width: 100%;
  text-align: center;
  box-shadow: -1px 1px 16px -7px rgba(0, 0, 0, 0.75) !important;
  -webkit-box-shadow: -1px 1px 10px -7px rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: -1px 1px 16px -7px rgba(0, 0, 0, 0.75) !important;
}

.listview {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.forate {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  color: white;
  font-weight: 500;
}


.histMia{
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #00000052;
}

.histMiaSub{
  margin-right: 15px;
  margin-top: 15px;
}

.histMiaSub img {
  height: 24px;
  margin-right: 5px;
}

.histMiaSub span {
  font-size: 14px;
  font-weight: 600;
  color: #05bf05;
}

.gameboxlable2 {
  font-size: 13px;
  font-weight: 400;
}

.gameboxmain {
  color: var(--color-accent);
  font-weight: 600;
  font-size: 14px;
  border-top-left-radius: 10px;
  margin-left: 5px;
  border-top-right-radius: 10px;
}

.divgame {
  font-size: 26px;
    margin-top: 8px;
}

.gameBoxMina {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.gameBox {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 10px;
  margin-bottom: 10px;
  
  box-shadow: -1px 1px 16px -7px rgba(0, 0, 0, 0.75) !important;
  -webkit-box-shadow: -1px 1px 10px -7px rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: -1px 1px 16px -7px rgba(0, 0, 0, 0.75) !important;
}

.withdrawWallet {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.withdrawp {
  text-align: center;
    font-size: 14px;
    margin-bottom: 20px;
}

.withdrawButton {
  border-radius: 40px !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  color: white !important;
  padding: 12px !important;
}



.withdrawWallet img {
  width: 58px;
  margin-right: 15px;
}


.withdrawWallet div {
  display: flex;
  flex-direction: column;
}

.withdrawWallet span {
 font-weight: 700;
}




.gameboxtitle {
  background-color: var(--color-accent);
  color: white;
  font-weight: 600;
  font-size: 14px;
  padding: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
}

.gameboxinfo {
  font-size: 13px;
  text-align: center;
    border-top: solid 1px #00000036;
    padding: 5px;
}

.datePick {
  width: 100%;
    margin-bottom: 10px;
    border-radius: 4px;
    padding: 5px;
    border: solid 1px #0000002e;
    text-align: center;
    background: #fff;
}

.datepickerdiv {
  height: 55px;
    width: 55px;
    position: absolute;
    bottom: 13px;
    right: 13px;
    padding: 6px;
    background: white;
    display: flex;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    box-shadow: -1px 1px 16px -7px rgba(0, 0, 0, 0.75) !important;
    -webkit-box-shadow: -1px 1px 10px -7px rgba(0, 0, 0, 0.75) !important;
    -moz-box-shadow: -1px 1px 16px -7px rgba(0, 0, 0, 0.75) !important;
}

.datepickerdiv img {
  height: 35px;
    width: 35px;
}

.gameboxlable {
  font-size: 12px;
}

.gameBox .transaction_block div {
  text-align: center;
}

.dehli_play img {
  width: 40px !important;
}

.dehli_play {
  display: flex;
  flex-direction: column;
  width: max-content;
  align-items: center;
}

.dehli_play span {
  color: black;
  font-size: 12px;
  font-weight: 700;
  margin-top: 5px;
}

.animated {
  height: 100vh;
  z-index: 10;
}

a {
  text-decoration: none !important;
  color: unset !important;
}

.loginTopBlock {
  width: 100%;
    display: flex;
    margin-left: -15px;
    align-items: center;
}

.loginTopBlock div {
  background: #F99C1A;
  height: 68px;
  width: 20px;
}

.loginTopBlock span {
  margin-left: 20px;
  font-size: 21px;
  font-weight: 600;
  color: black;
}

.SplashDiv {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

.backdraw {
  position: absolute;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  background: #0000008f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toolbar {
  padding: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toolbar div {
  display: flex;
  align-items: center;
}

.toolbar img {
  height: 32px;
}

.toolbar .wallet-img {
  height: 21px;
}
.toolbar .wallet-text {
  font-size: 14px !important;
  margin-left: 5px;
  margin-right: 5px;
}
.toolbar span {
  font-size: 17px;
  margin-left: 10px;
  color: black;
  font-weight: 600;
  padding: 7px;
}

.market_name {
  text-align: left;
  color: black;
  font-size: 19px;
  font-weight: 800;
}


.game-play-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.game-play-box span {
  font-size: 14px;
  font-weight: 600;
}

.game-play-box select {
  margin-bottom: 0px !important;
  border-radius: 30px  !important;
  padding: 6px !important;
  text-align: center  !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  border: solid 1px #000 !important;
  width: 49%;
  background-color: var(--color-white);
  align-self: stretch;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}


.game-play-box input {
  margin-bottom: 0px !important;
  border-radius: 30px  !important;
  padding: 6px !important;
  text-align: center  !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  border: solid 1px #000 !important;
}

.game-play-add {
  display: flex;
  justify-content: flex-end;
  margin-top: 11px;
}

.game-play-add button {
  width: 50% !important;
  font-size: 13px;
  font-weight: 600;
  color: white;
  border-radius: 4px;
}

.passbook {
  overflow-x: scroll;
  width: 0;
  min-width: 100%;
}

.passbook_head {
  display: flex;
}

.passbook_item {
  display: flex;
}

.fundblock {
  margin-left: 8px !important;
    margin-right: 8px !important;
    width: unset !important;
    border-radius: 11px !important;
    box-shadow: -1px 1px 16px -7px rgba(0, 0, 0, 0.75) !important;
    -webkit-box-shadow: -1px 1px 10px -7px rgba(0, 0, 0, 0.75) !important;
    -moz-box-shadow: -1px 1px 16px -7px rgba(0, 0, 0, 0.75) !important;
    background: white !important;
    margin-bottom: 14px !important;
    padding: 10px 1px !important;
}

.subline {
  margin-right: 15px !important;
  color: black !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  text-align: left !important;
}

.passbook_head span {
  background-color: var(--color-accent);
  color: white;
  padding: 5px;
  text-align: center;
  border-right: solid 1px white;
  font-size: 12px;
  flex-shrink: 0;
}

.passbook_item span {
  color: black;
  padding: 5px;
  text-align: center;
  border-right: solid 1px rgba(0, 0, 0, 0.151);
  border-bottom: solid 1px rgba(0, 0, 0, 0.151);
  font-size: 12px;
  flex-shrink: 0;
}

.game-play-box div {
  width: 49%;
}

.main-game-screen {
  padding-bottom: 50px !important;
}

.game-play-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;
  position: fixed;
  width: 100%;
  bottom: 0;
  background: white;
  z-index: 2;
  border-top: solid 1px var(--color-accent);
  max-width: 500px;
}

.game-play-bottom-first-div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 70%;
}

.game-play-bottom .list-div {
  display: flex;
    flex-direction: column;
    align-items: center;
}

.game-play-bottom button {
  margin-right: 10px;
  font-size: 13px;
  padding: 11px 40px;
  color: white;
  font-weight: 600;
  border-radius: 4px;
}

.game-play-bottom span {
  font-size: 14px;
  font-weight: 500;
}

.market_result {
  text-align: left;
  color: #F99C1A;
}

.times {
  text-align: left;
  color: black;
}



.bottomNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background: white;
  padding: 5px 0px;
  position: fixed;
    width: 100%;
    bottom: 0;
    background: white;
    z-index: 2;
    box-shadow: -1px 1px 16px -7px rgba(0,0,0,0.75);
-webkit-box-shadow: -1px 1px 16px -7px rgba(0,0,0,0.75);
-moz-box-shadow: -1px 1px 16px -7px rgba(0,0,0,0.75);
max-width: 500px;
}

.bottomNav img {
  height: 25px;
  width: 25px;
  margin-bottom: 5px;
}

.bottomNav div {
  display: flex;
    flex-direction: column;
    align-items: center;
}

.games .imgBlock {
  background: white;
  border-radius: 100%;
  padding: 28px;
}

.games .maingameblock {
  display: flex;
  flex-direction: column;
}

.games span {
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
}


.bottomNav .home {
  background: #F99C1A;
  height: 50px;
  width: 50px;
  border-radius: 100%;
}

.bottomNav .home img {
    height: 50px;
    width: 50px;
    padding: 12px;
}

.bottomNav span {
  font-size: 12px;
  font-weight: 600;
}

.whatsappdiv {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 10px;
}

.whatsappdiv div {
  display: flex;
    align-items: center;
}

.whatsappdiv img {
    height: 25px;
    width: 25px;
    margin-right: 5px;
}

.whatsappdiv span {
  margin-bottom: 0px !important
}

.nobackdraw {
  display: none !important;
}

.backdraw img {
  border-radius: 10px;
}

.RootWidth {
  width: 500px;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.RootView {
  height: 100vh;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.button {
  cursor: pointer;
  border: 0;
  padding: 10px;
  background-color: var(--button-background);
  align-self: stretch;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
}

.button b {
  position: relative;
  font-size: var(--font-size-xl);
  display: inline-block;
  font-family: var(--font-inika);
  color: var(--button-textcolor);
  text-align: left;
}

.button a {
  width: 100%;
}

.mainBulk {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.mainBulk .item {
  width: 32%;
  background: var(--color-accent);
  border-radius: 15px;
  display: flex;
  margin-bottom: 4px;
  position: relative;
  user-select: none;
}

.mainBulk .digit {
  font-size: 29px;
  text-align: center;
  width: 100%;
  color: white;
  font-weight: 600;
  padding: 14px 20px;
}

.mainBulk .amount {
  color: white;
  position: absolute;
  right: 13px;
}

.noticeText {
  margin-bottom: 5px;
  text-align: center;
  font-weight: 700;
  font-size: 13px;
  margin-top: 16px;
}

.edittext {
  border-radius: 5px;
  border: solid 2px var(--color-accent) !important;
  background-color: var(--color-white);
  align-self: stretch;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  padding: 10px !important;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}

.singlepanabulk {
  display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.singlepanabulk span {
  color: white;
    width: 100%;
    margin-right: 5px;
    font-size: 18px;
    padding: 7px 10px;

}

.radioGroup {
  display: flex;
    justify-content: space-around;
    font-weight: 600;
    font-size: 14px;
}

.radioGroup input {
  width: unset !important;
  margin-right: 5px;
}

.think-border {
  border: solid 3px var(--color-accent) !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.think-border2 {
  border: solid 3px var(--color-accent) !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}


.think-border3 {
  border: solid 3px var(--color-accent) !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.think-border3 span {
  font-weight: 700;
}

.think-border2 span {
  font-weight: 700;
}

.think-border span {
  font-weight: 700;
}

.edittext:active {
  animation: 1s ease 0s infinite normal none shadow-drop-bottom;
  opacity: 1;
}

.pageHeadings {
  position: relative;
  font-size: var(--font-size-2xl);
  font-family: var(--font-inika);
  color: var(--color-font);
  text-align: center;
  display: inline-block;
  font-weight: 600;
  width: 100%;
  height: 27px;
  flex-shrink: 0;
}

.authBack {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

.inputBlock {
  display: flex;
  align-items: center;
  background: #eee;
  padding: 5px;
  width: 100%;
  border-radius: 40px;
}

.inputBlock img {
  height: 43px;
}

.inputBlock input {
  background: transparent;
  border: none;
  width: 100%;
  margin-left: 9px;
  color: black;
  font-size: 15px;
  font-weight: 500;
}

.inputBlock input:focus-visible {
  outline: none;
}


.borderdiv {
  border: solid 2px #0000004d;
  border-radius: 10px;
}

iframe {
  width: 100%;
  height: 90vh;
}

.suggest {
  list-style-type: none;
  padding-left: 0;
  -webkit-box-shadow: 0px 0px 23px -5px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 23px -5px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 23px -5px rgba(0,0,0,0.75);
  position: absolute;
  background: white;
  width: 45%;
  max-height: 281px;
  overflow-y: scroll;
  max-width: 230px;
  text-align: center;
}
.homedlg {
  padding: 0 !important;
}

.homedlg .dlgtoolbar {
  display: flex;
  justify-content: space-between;
  padding: 10px 19px;
  background: var(--color-accent);
  font-weight: 500;
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.homedlg .main-dlg-content {
  display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 25px;
    background: #f99c1a14;
}

.homedlg .main-dlg-content .appimg {
  width: 35%;
    border-radius: 12px;
}

.homedlg .main-dlg-content .textblock {
  width: 55%;
  text-align: right;
  display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: flex-end;
}
.homedlg .main-dlg-content .textblock .title {
  font-size: 18px;
  font-weight: 700;
}
.homedlg .main-dlg-content .textblock .desc {
  text-align: right;
    font-size: 14px;
    font-weight: 600;
}

.dlgbtn {
  color: white !important;
  font-weight: 600 !important;
  align-self: auto !important;
}

.dlgbtn img {
  height: 20px;
  margin-right: 10px;
}
.suggest li {
  border-bottom: solid 1px rgba(0, 0, 0, 0.17);
  padding: 5px 5px 5px 5px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.17);
  font-weight: 600;
}

.qrImg {
  max-width: 100%;
  height: 200px;
  border: solid 1px #000;
  border-radius: 10px;
  margin-bottom: 10px;
}

.paymentStatusDiv {
  flex-direction: column !important;
}

.paymentStatusDiv .phead {
  font-weight: 700;
  font-size: 18px;
}
.paymentStatusDiv .psub {
  font-size: 13px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}
.towt{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100% !important;
}

.towt  span {
  margin-bottom: 5px;
}

.scd span {
  font-weight: 700;
}

.towt .frs {
  display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.towt .scd {
  display: flex;
    flex-direction: column;
    align-items: center;
}

.nav-top-head {
  padding: 25px 20px;
}

.nav-top-head img {
  height: 39px;
  margin-right: 10px;
}

.nav-top-child1 {
  display: flex;
  flex-direction: column;
}
.nav-top-child1 span {
  color: black;
  font-size: 14px;
}

.nav-item span {
  font-size: 14px;
    font-weight: 600;
}

.popup {
  z-index: 10000;
  position: fixed;
  width: 100%;
  max-width: 500px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background: #000000a8;
}

.popup .main {
  max-height: 80vh;
}


.chartButton {
  background: #097d0c;
  color: white;
  border-radius: 23px;
  padding: 8px 5px;
  width: 100%;
  text-align: center;
}

.wallet-blocks {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background: #000428;
  border-radius: 18px;
  color: white;
  font-weight: 600;
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
}

.wallet-block-main {
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  padding: 5px;
  justify-content: space-around;
}

.wallet-blocks img {
  height: 57px;
  width: 57px;
  background: #fff;
  padding: 8px;
  border-radius: 39px;
  margin-bottom: 5px;
}

.popup .cancel {
  width: 30px;
  height: 30px;
}


.marquee {
  height: 20px;
  overflow: hidden;
  position: relative;
  color: red;
}

.marquee span {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 30px;
  text-align: center;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -moz-animation: scroll-left 2s linear infinite;
  -webkit-animation: scroll-left 2s linear infinite;
  animation: scroll-left 20s linear infinite;
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
}

.main-play-box {
  display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.play-selection-group {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}

.play-selection-group span {
  background: #ffffff;
    width: 28px;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    font-weight: 700;
    border: solid 2px #f09f2d;
    color: #f09f2d;
    cursor: pointer;
}


.play-selection-group .active {
    background: #f09f2d;
    color: white;
    border: none;
}

.main-play-box div {
  border: solid 2px #f99c1a;
  border-radius: 7px;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 45%;
}

.main-play-box label {
  margin-right: 11px;
  color: #f99c1a;
  font-weight: 700;
}

.main-play-box input {
  border-bottom: solid 1px #d7d7d7 !important;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 6px !important;
  text-align: center !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-bottom: 0px !important;
}

@-moz-keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
  }
}

@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}



:root {
  /* fonts */
  --font-inika: Inika;

  /* font sizes */
  --font-size-xs: 11px;
  --font-size-sm: 12px;
  --font-size-base: 14px;
  --font-size-lg: 15px;
  --font-size-xl: 16px;
  --font-size-2xl: 20px;
  --font-size-3xl: 24px;

  /* Colors */
  --color-font: #000;
  --color-accent: #F99C1A;
  --button-background: #F99C1A;
  --button-textcolor: #fff;


  --color-white: #fff;
  --color-pink-100: #ffcbcb;
  --color-pink-200: #fcb6b6;
  --color-pink-300: #ff8585;
  --color-red: #ff8585;
  --color-gray: #1f1f1f;
  --color-black: #000;
  --color-gray-100: #ffeded;
  --color-gray-200: #f0f0f0;
  --color-gray-300: #cbe0ff;
  --color-gray-400: #d1d1d1;
  --color-gray-500: #4b5a91;
  --color-gray-600: #4f4d4d;
  --color-gray-700: rgba(0, 0, 0, 0.83);
  --color-gray-800: rgba(0, 0, 0, 0.22);
  --color-gray-900: rgba(0, 0, 0, 0.22);
  --color-gray-1000: rgba(0, 0, 0, 0.83);
  --color-gray-1100: rgba(0, 0, 0, 0.5);
  --color-blue-100: #0b32ff;
  --color-blue-200: rgba(0, 26, 255, 1);
  --color-blue-300: rgba(0, 26, 255, 0.5);
  --color-green-100: rgba(0, 160, 16, 1);

  /* Gaps */
  --gap-0: 0px;
  --gap-xs: 1px;
  --gap-sm: 5px;
  --gap-md: 10px;
  --gap-lg: 12px;
  --gap-xl: 24px;

  /* Paddings */
  --padding-4xs: 5px;
  --padding-3xs: 6px;
  --padding-2xs: 7px;
  --padding-xs: 8px;
  --padding-sm: 9px;
  --padding-md: 10px;
  --padding-lg: 17px;
  --padding-xl: 20px;
  --padding-2xl: 28px;
  --padding-3xl: 34px;
  --padding-4xl: 34px;

  /* border radiuses */
  --br-2xs: 5px;
  --br-xs: 6px;
  --br-sm: 7px;
  --br-md: 9px;
  --br-lg: 12px;
  --br-xl: 22px;
  --br-2xl: 22px;
}

